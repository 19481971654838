import React from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';

const Container = styled.div`
  width: 90%;
  max-width: 300px;
  aspect-ratio: 1 / 1;
  height: auto;
  max-height: 300px;
  position: relative;
  overflow: hidden;
`;
const Pin = styled(LinearLayout)`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  pointer-events: none;
  user-select: none;
  & > * {
    pointer-events: initial;
  }
`;

const s = 80;
const pins = [
  [0, 3],
  [0, 2],
  [1, 2],
  [0, 1],
  [1, 1],
  [2, 1],
  [0, 0],
  [1, 0],
  [2, 0],
  [3, 0],
].map(
  ([x, y]) => [x * s/3 + y * s/6, y * s*Math.sqrt(3)/6]
).map(
  ([x, y]) => [x + 50 - s/2, y + 50 - s*Math.sqrt(3)/4]
);

export default function PinView({ children, ...props }){
  return (
      <Container>
        {pins.map(([x, y], i) =>
          <Pin style={{ left: `${x}%`, top: `${y}%` }} key={i+1}>
            {children[i]}
          </Pin>)}
      </Container>
  );
}