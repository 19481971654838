import React, { useState } from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate, useLocation } from "react-router-dom";

import LinearLayout from '../components/LinearLayout';
import ToggleButton from '../components/ToggleButton';
import { useAPIContext } from '../contexts/API';

const Container = styled(LinearLayout)`
  width: 100%;
  height: 45px;
  position: sticky;
  top: 0;
  // flex-wrap: nowrap;
  flex-flow: wrap;
  transition: 0.2s;
  overflow-y: visible;
  // overflow-y: hidden;
  // &.show {
  //   overflow-y: visible;
  // }
  z-index: 10;
`;
const Item = styled(LinearLayout)`
  border-bottom: 1px solid white;
  background: gray;
  width: 100%;
  height: 100%;
  transition: 0.2s;
  overflow-y: hidden;
  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  &.hide {
    border: none;
    height: 0;
  }
  &:first-of-type {
    cursor: initial;
  }
`;
const SubItem = styled(Item)`
  &:first-of-type {
    cursor: pointer;
  }
  &:not(:last-of-type) {
    border-right: 1px solid white;
  }
`;
const More = styled.div`
  display: flex;
  position: absolute;
  left: 10px;
`;

const authed_pages = [
  { title: "Overview", path: "/" },
  { title: "Statistics", path: "/statistics" },
  { title: "+ New Game", path: "/new-game" },
  [ { title: "Password", path: "/change-password" },
    { title: "Sign Out", path: "/sign-out" }, ],
  { title: "Review", path: "/review", show: false },
];
const admin_pages = [].concat(authed_pages, [
  // { title: "Admin", path: "/admin" },
]);
const unauthed_pages = [
  { title: "Login", path: "/" },
  { title: "Sign Up", path: "/signup" },
];
function isarr(x){ return x.length !== undefined; }

export default function Header({ title, ...props }){
  const { user } = useAPIContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const pages = (() => {
    if(user?.sub?.role === "admin") return admin_pages;
    if(user) return authed_pages;
    if(!user) return unauthed_pages;
  })();


  const first = location.pathname;
  const [now, ...others] = [].concat(
    [].concat(
      pages.flat().filter(e => e.path === first),
      pages.flat().filter(e => first.startsWith(e.path)).sort((a, b) => b.path.length - a.path.length),
    ).slice(0, 1),
    pages.filter(e => e.path !== first).filter(e => isarr(e) || e.show !== false)
  );

  return (
    <Container {...props} className={show ? 'show' : ''}>
      <Item>{now.title}</Item>
      {others.map((e, i) => isarr(e)
        ? <Item key={e[0].title} className={!show ? "hide" : ""}>
            {e.map((x, i) =>
              <SubItem style={{ width: `${100/e.length}%` }} key={x.title} onClick={() => navigate(x.path)}>
                {x.title}
              </SubItem>)}
          </Item>
        : <Item key={e.title} onClick={() => navigate(e.path)} className={!show ? "hide" : ""}>
            {e.title}
          </Item>)}
      {others.length === 0 ||
        <More>
          <ToggleButton
            set={setShow}
            val={show}
            dir=">v" />
        </More>}
    </Container>
  );
}