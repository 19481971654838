import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ScatterChart, Scatter, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import ScoreBoard from '../components/ScoreBoard';
import ToggleButton from '../components/ToggleButton';
import { Stat, Game, sum, dateString, reversed } from '../utils';
import { API_URL, useAPIContext } from '../contexts/API';

const LL = styled(LinearLayout)`
  min-height: 100vh;
  justify-content: flex-start;
  & > *{
    margin-bottom: 30px;
    width: 100%;
    max-width: 600px;
  }
  & > *:first-of-type {
    max-width: initial;
  }
`;

const Container = styled(LinearLayout)`
  background: #cdcdcd;
  margin: 10px;
  padding: 5px 8px;
  width: -webkit-fill-available;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  flex-wrap: nowrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  & > * {
    margin: 0;
  }
`;
const Item = styled.div`
  margin-top: 5px;
  width: 100%;
  font-family: monospace;
  background: #efefef;
  white-space: pre-wrap;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  box-sizing: border-box;
  font-size: small;
  cursor: pointer;
  &.special {
    box-shadow: 1px 2px black;
  }
`;
const Key = styled.span`
  color: #808080;
`;
const Btns = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`;
const PlotContainer = styled.div`
  & svg { overflow: visible; }
`;

function Shape({ cx, cy }){
  return(
    <circle
      // {...props}
      cx={cx} cy={cy}
      stroke="white"
      strokeWidth="1.5"
      fill="#f0523c"
      r="4" />
  );
}

const date2str = d => {
  return `${d.getMonth()+1}/${d.getDate()}`;
};

export default function Overview({}){
  const navigate = useNavigate();
  const { request, deleteGame } = useAPIContext();
  const [data, setData] = useState(false);
  const [timePlot, setTimePlot] = useState(true);
  const { games } = data || {};
  const scores = useMemo(() => games &&
    games.map(e => Object.assign(new Game(e.data, (e.category || "practice").split(".")[0]), { game: e }))
         .map(e => ({ ...e, stat: new Stat(e) })), [games]);
  const [more, setMore] = useState(false);

  useEffect(() => {
    (async () => {
      let res = await request('/list-game');
      if(res.ok){
        setData({ games: (await res.json()).data });
      }else{
        window.confirm("error");
      }
    })();
  }, []);

  if(data === false) return '';
  return (
    <>
      <LL>
        <Header />
        {games.length === 0 ||
          <PlotContainer style={{ padding: '0 10px', boxSizing: 'border-box' }}>
            <ResponsiveContainer width="100%" aspect={1 / 0.7}>
              <ScatterChart
                data={scores.filter(e => e.complete && e.cat === "practice")}
                margin={{ top: 10, right: 5, left: -20, bottom: 0 }}>
                <CartesianGrid />
                <Scatter
                  line={{stroke: '#f0523c', strokeWidth: 2}}
                  dataKey="total"
                  fill="#f0523c"
                  shape={Shape} />
                {timePlot
                  ? <XAxis
                      type="number"
                      dataKey="game.time"
                      domain={['dataMin', 'dataMax']}
                      tick={p =>
                        <text x={p.payload.coordinate} y={p.y} textAnchor="middle" alignmentBaseline="before-edge">
                         {date2str(new Date(p.payload.value))}
                        </text>} />
                  : <XAxis domain={['dataMin', 'dataMax']} />}
                <YAxis type="number" dataKey="total" />
              </ScatterChart>
            </ResponsiveContainer>
            <Btns>
              <Button
                className={timePlot ? "" : "disabled"}
                onClick={() => setTimePlot(e => !e)}>Time Plot</Button>
            </Btns>
          </PlotContainer>}
        <Container>
          <Title>
            <h3>History</h3>
            {/*<ToggleButton
              set={setMore}
              val={more}
              dir="<v" />*/}
          </Title>
          {reversed(more || true ? scores : scores.slice(-5)).map((e, i) =>
            // <Item key={i} onClick={() => { deleteGame(e); window.location.reload(); }}>
            <Item
              key={i}
              onClick={() => navigate(`/review/${e.game.id}`)}
              className={e.cat !== "practice" ? "special" : ""}
            >
              <span><Key>Score: </Key>{(`   ${e.total}`.slice(-3))}</span>
              {e.cat !== "practice"
                ? <span>{e.cat.split('.')[0]}</span>
                : e.complete
                ? <span><Key>Strikes:</Key>{`  ${e.stat.strike}`.slice(-2)}</span>
                : <span><Key>incomplete</Key></span>}
              <span>{dateString(new Date(e.game.time))}</span>
            </Item>)}
        </Container>
        {/*scores.map((e, i) =>
            <ScoreBoard
              key={i}
              style={{ width: '100%' }}
              rounds={e.rounds}
              scores={e.scores} />)*/}
      </LL>
    </>
  );
}