import React from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const style = {
  "^": { transform: 'rotate(-180deg)' },
  "v": {},
  "<": { transform: 'rotate(90deg)' },
  ">": { transform: 'rotate(-90deg)' },
}, common = {
  transition: '0.2s',
  cursor: 'pointer'
};

export default function ToggleButton({ set, val, dir }){
  return (
    <ExpandMoreIcon
      onClick={() => set(e => !e)}
      sx={{ ...common, ...style[dir[Number(val)]] }} />
  );
}