import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
} from "react-router-dom";

import logo from './logo.svg';
import './App.css';
import Recorder from './screens/Recorder';
import Login from './screens/Login';
import Overview from './screens/Overview';
import SignOut from './screens/SignOut';
import Signup from './screens/Signup';
import Statistics from './screens/Statistics';
import Review from './screens/Review';
import ChangePwd from './screens/ChangePwd';
import { API, useAPIContext } from './contexts/API';

function Router() {
  const { user } = useAPIContext();
  if(!user) return (
    <Routes>
      <Route path="/signup" element={<Signup />} />
      <Route path="*" element={<Login />} />
    </Routes>
  );
  return (
    <Routes>
      {/*<Route path="/history" element={<Recorder />} />*/}
      <Route path="/" element={<Overview />} />
      <Route path="/new-game" element={<Recorder />} />
      <Route path="/sign-out" element={<SignOut />} />
      <Route path="/statistics" element={<Statistics />} />
      <Route path="/review/:gid" element={<Review />} />
      <Route path="/change-password" element={<ChangePwd />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
}

function App() {
  return (
    <API>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </API>
  );
}

export default App;
