import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { redirect, useParams, useSearchParams } from "react-router-dom";

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import ScoreBoard from '../components/ScoreBoard';
import PinView from '../components/PinView';
import { useAPIContext } from '../contexts/API';
import { Stat, ROUNDS, FULL, PINS, Game, last, mkArray } from '../utils';

const ScoreBoardContainer = styled.div`
  overflow-x: scroll;
  max-width: 100%;
  // margin: 20px 0px;
`;
const Frames = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: flex-start;
  & > div{
    box-sizing: border-box;
    user-select: none;
    text-align: center;
  }
  & > div:first-of-type, & > div:last-child {
    padding: 5px 12px;
    border: 1px black solid;
    background: #cdcdcd;
    width: 25%;
    max-width: 125px;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
  }
  & > div:first-of-type {
    border-left: none;
  }
  & > div:last-child {
    border-right: none;
  }
`;
const Focused = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 250px;
`;
const FocusedTop = styled.div`
  padding: 5px 12px;
`;
const Scores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > div {
    width: -webkit-fill-available;
    border: 2px solid #8c8c8c;
    padding: 12px 0;
    box-sizing: border-box;
  }
  & > div {
    border-right: none;
  }
  & > div.focus {
    border-right: 2px solid red;
    border-color: red;
  }
  & > div.focus ~ div {
    border-right: 2px solid #8c8c8c;
    border-left: none;
  }
`;
const Pin = styled.div`
  width: 13%;
  height: 13%;
  border-radius: 50%;
  background: #c8c8c8;
  user-select: none;
  &.on {
    background: red;
    outline: 2px solid black;
    outline-offset: 4px;
  }
  &.hidden {
    display: none;
  }
  &.close {
    pointer-events: none;
  }
`;
const Btns = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  padding-bottom: 30px;
`;

const warning = e => { e.preventDefault(); e.returnValue = ''; }

export default function Review({}){
  const { request, deleteGame } = useAPIContext();
  const { gid } = useParams();
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category") || null;
  const [focus, setFocus] = useState(0);
  const [fetching, setFetching] = useState(false);
  const [data, setData] = useState(false);
  const { games } = data || {};
  const scores = useMemo(() => games &&
    games.filter(e => e.id === gid).map(e => Object.assign(
      new Game(e.data, category === null ? (e.category || "").split(".")[0] : category),
      { game: e }
    )), [games]);
  const SBRef = useRef(null);

  useEffect(() => {
    (async () => {
      let res = await request('/list-game');
      if(res.ok){
        setData({ games: (await res.json()).data });
      }else{
        window.confirm("error");
      }
    })();
  }, []);

  if(data === false) return '';
  if(scores.length === 0) return window.location = '/';
  const game = { ...scores[0], stat: new Stat(scores[0]) };
  const second = game.at_round[focus] === game.at_round[focus-1];
  const hidden = second ? game.throws[focus-1] : 0;
  const pins = FULL - game.throws[focus] - (second ? game.throws[focus-1] : 0);
  const at_round = Math.min(game.at_round[focus], ROUNDS);

  return (
    <>
      <LinearLayout style={{ minHeight: '100vh', justifyContent: 'space-between' }}>
        <Header />
        <h3>{game.cat}</h3>
        <ScoreBoardContainer ref={SBRef}>
          <ScoreBoard
            style={{ width: '600px', boxSizing: 'border-box' }}
            game={game}
            parent={SBRef.current}
            focus={focus} />
        </ScoreBoardContainer>
        {/*<Frames>
          <div style={at_round === 1 ? { visibility: 'hidden' } : {}}>{at_round-1}</div>
          <Focused>
            <FocusedTop>{at_round}</FocusedTop>
            <Scores>
              {game.rounds[at_round-1].map((e, i, a) =>
                <div className={i === a.length-1 ? "focus" : ""} key={i}>{e}</div>)}
              {mkArray(Math.max(0, 2 - last(game.rounds).length), (e, i) => <div key={i}></div>)}
            </Scores>
          </Focused>
          <div style={at_round == ROUNDS ? { visibility: 'hidden' } : {}}>{at_round+1}</div>
        </Frames>*/}
        <PinView>
          {(new Array(PINS)).fill(0).map((e, i) =>
            <Pin
              key={i+1}
              className={(hidden & (1 << i)) ? "hidden" : (pins & (1 << i)) ? "on" : undefined} />)}
        </PinView>
        <Btns>
          <Button onClick={() => setFocus(e => Math.max(e-1, 0))}>&lt;</Button>
          <Button onClick={async () => {
            if(fetching) return;
            setFetching(true);
            try{
              if(await deleteGame(game))
                window.location = '/';
            }finally{
              setFetching(false);
            }
          }} style={{ background: 'red' }}>Delete Game</Button>
          <Button onClick={() => setFocus(e => Math.min(e+1, game.throws.length-1))}>&gt;</Button>
        </Btns>
      </LinearLayout>
    </>
  );
}