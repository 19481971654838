import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';

const LL = styled(LinearLayout)`
  min-height: 100vh;
  justify-content: flex-start;
  & > input{
    // margin-bottom: 15px;
  }
  & > label{
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

const Btns = styled.div`
  display: flex;
  & > * {
    margin: 10px;
  }
  margin-top: 10px;
`;

export default function Recorder({}){
  const { request } = useAPIContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [realname, setRealname] = useState("");
  const [id, setId] = useState("");
  const [dep, setDep] = useState("");

  async function signup(){
    if(!username || !username.match(/^[0-9a-zA-Z_]+$/))
      return window.confirm("Invalid username.");
    if(password.length < 8)
      return window.confirm("Password must have at least 8 characters.");
    if(password != password2)
      return window.confirm("Password confirmation failed.");
    if(!realname) return window.confirm("Real name cannot be empty.");
    if(!id) return window.confirm("Student ID cannot be empty.");
    if(!dep) return window.confirm("Department cannot be empty.");
    if(!window.confirm(`Signing up with\n    Username: ${username}\n    Name: ${realname}\n    Student ID: ${id}\n    Department: ${dep}`))
      return;
    let res = await request('/signup', {
      method: 'POST',
      body: JSON.stringify({
        username, password, realname, id, department: dep
      })
    });
    if(res.ok){
      console.log(res);
      window.location = '/';
    }else{
      window.confirm((await res.json()).error.toString());
    }
  }

  async function login2(){
    setUsername(window.prompt("Username:"));
    setPassword(window.prompt("Password:"));
  }

  return (
    <>
      <LL>
        <Header />

        <label>Account Name (0-9 a-z A-Z _)</label>
        <input value={username} onChange={e => setUsername(e.target.value)} type="text" />
        <label>Password</label>
        <input value={password} onChange={e => setPassword(e.target.value)} type="password" />
        <label>Password confirmation</label>
        <input value={password2} onChange={e => setPassword2(e.target.value)} type="password" style={
          password2 && password2 != password ? { borderColor: 'red' } : {}
        }/>
        <label>Name</label>
        <input value={realname} onChange={e => setRealname(e.target.value)} type="text" />
        <label>Student ID</label>
        <input value={id} onChange={e => setId(e.target.value)} type="text" />
        <label>Department (e.g. 資工三)</label>
        <input value={dep} onChange={e => setDep(e.target.value)} type="text" />
        <Btns>
          {/*<Button onClick={login2}>Can't type?</Button>*/}
          <Button onClick={signup}>Sign Up</Button>
        </Btns>
      </LL>
    </>
  );
}