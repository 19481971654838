export function sum(x){ return x.reduce((a, b) => a + b, 0);}
export function last(x){ return x[x.length-1]; }
export function count(x){ return x > 0 ? count(parseInt(x / 2)) + x % 2 : 0; }
export const ROUNDS = 10;
export const PINS = 10;
export const FULL = (1 << PINS) - 1;
export function mkArray(n, f){ return new Array(n).fill(0).map(f); }
export function dateString(d){ return `${String(d.getMonth()+1).padStart(2, '0')}/${String(d.getDate()).padStart(2, '0')}`; }
export function reversed(x){ return x.map(e => e).reverse(); }
export function percentage(x){ return isFinite(x) ? `${(100 * x).toFixed(1)}%` : "NaN"; }

export class Game{
  constructor(throws, cat){
    this.throws = throws.map(e => e);
    this.cat = cat || "practice";
    this.rounds = [[]];
    this.states = [[]];
    this.index = [[]];
    this.scores = [];
    this.at_round = [];
    this.valid = true;
    this.complete = true;
    for(let i = 0; i < this.throws.length; i++){
      let e = this.throws[i], c = count(e), l = 0, s;
      if(e !== parseInt(e) || e > FULL || e < 0)
        return console.error('invalid-1'), this.valid = false;
      if(last(this.rounds).length == 0){
        last(this.rounds).push(c);
        last(this.states).push(e);
        last(this.index).push(i);
        this.at_round.push(this.rounds.length);
        if(c == PINS){
          s = sum(last(this.rounds));
          this.rounds.push([]);
          this.states.push([]);
          this.index.push([]);
          if(i+1 < this.throws.length) s += count(this.throws[i+1]);
          if(i+2 < this.throws.length) s += count(this.throws[i+2]);
          else this.complete = false;
          this.scores.push(s);
        }
        this.remaining = FULL ^ e || FULL;
      }else{
        if(last(this.rounds).length != 1)
          return console.error('invalid-2'), this.valid = false;
        l = this.throws[i-1];
        if((l & e) != 0)
          return console.error('invalid-3'), this.valid = false;
        last(this.rounds).push(c);
        last(this.states).push(e);
        last(this.index).push(i);
        this.at_round.push(this.rounds.length);
        s = sum(last(this.rounds));
        this.rounds.push([]);
        this.states.push([]);
        this.index.push([]);
        if(s == PINS){
          if(i+1 < this.throws.length) s += count(this.throws[i+1]);
          else this.complete = false;
        }
        this.scores.push(s);
        this.remaining = FULL
      }
      // if(this.scores.length == ROUNDS) break;
    };
    if(last(this.rounds).length == 0){
      this.rounds.pop();
      this.states.pop();
      this.index.pop();
    }
    if(this.rounds.length > ROUNDS && this.scores.length < this.rounds.length)
      this.scores.push(0);
    while(this.rounds.length > ROUNDS){
      let x = this.rounds.pop();
      last(this.rounds).push(...x);
      x = this.states.pop();
      last(this.states).push(...x);
      x = this.index.pop();
      last(this.index).push(...x);
      this.scores.pop();
    }
    if(this.scores.length != ROUNDS) this.complete = false;
    if(this.scores.length == ROUNDS){
      let x = last(this.rounds);
      if(x[0] == 10) this.complete = x.length === 3;
      else{
        if(x.length <= 1) this.complete = false;
        else if(x[0] + x[1] === 10) this.complete = x.length === 3;
        else this.complete = true;
      }
    }

    if(this.cat === "final-exam" || this.cat === "midterm-exam"){
      let value;
      if(this.cat === "midterm-exam"){
        value = x => {
          if(x.length == 0) return 0;
          if(x[0] == FULL) return 10 + value(x.slice(1));
          if(x.length >= 2 && (x[0] | x[1]) == FULL) return 10 + value(x.slice(2));
          if(x[0] & 1) return 10 + value(x.slice(2));
          if(x.length == 1) return count(x[0]);
          return count(x[0]) + count(x[1]) + value(x.slice(2));
        }
      }
      if(this.cat === "final-exam"){
        value = x => {
          if(x.length == 0) return 0;
          if(x[0] == FULL) return 12 + value(x.slice(1));
          if(x.length >= 2 && (x[0] | x[1]) == FULL) return 12 + value(x.slice(2));
          if(x[0] & 1) return 10 + value(x.slice(2));
          if(x.length == 1) return count(x[0]);
          return count(x[0]) + count(x[1]) + value(x.slice(2));
        }
      }
      // console.log(this);
      // console.log(this.rounds.map(e => e.join(' ')).join('\n'));
      // console.log(this.throws);
      // console.log(this.scores);
      for(let i = 0; i < this.scores.length; i++){
        if(i !== this.scores.length-1){
          this.scores[i] = value(this.states[i]);
        }else{
          const s = [...this.states[i], 0, 0, 0];
          if(s[0] == FULL){
            this.scores[i] = value(this.states[i].slice(0, 3));
          }else if(s[0] | s[1] == FULL){
            this.scores[i] = value(this.states[i].slice(0, 3));
          }else{
            this.scores[i] = value(this.states[i].slice(0, 2));
          }
        }
      }
    }
    this.total = sum(this.scores);
    // console.log(this.rounds);
    // console.log(this.scores);
  }
};

export class Stat{
  check_frame(arr){
    if(arr.length === 0) return;
    if(arr[0] === 10){
      this.strike++;
      this.check_frame(arr.slice(1));
    }else if(sum(arr.slice(0, 2)) === 10){
      this.spare++;
      this.check_frame(arr.slice(2));
    }
  }
  constructor(game){
    this.game = game
    this.n = this.game.rounds.length;
    this.strike = this.spare = 0
    for(let i = 0; i < this.n; i++){
      this.check_frame(this.game.rounds[i]);
    }
  }
}

// console.log(new Game([
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
//   3,
//   12,
// ]));
// console.log(new Game([
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
//   FULL,
// ]));