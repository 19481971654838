import React, { useEffect, createContext, useContext, useMemo, useCallback, useState, useRef } from "react";
import jwt_decode from "jwt-decode";
import { CookiesProvider, useCookies } from 'react-cookie';

const API_URL = process.env.REACT_APP_API_URL;

export const APIContext = createContext(false);
export const useAPIContext = () => useContext(APIContext);

export function API({ children }){
  const [cookies, scookie, rmcookie] = useCookies();
  const token = useMemo(() => cookies?.bowling || null, [cookies]);
  const [user] = useMemo(() => {
    try{
      return [token && jwt_decode(token)];
    }catch(e){
      console.error(e);
      // if(e.name === 'InvalidTokenError') 
      return [null];
    }
  }, [token]);
  // useEffect(() => console.log(user), [user]);

  const request = async (a, b={}, ...c) => {
    const res = await fetch(API_URL + a, {
      ...b, headers: {
        ...(b.headers || {}),
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }, ...c);
    if(res.status == 401){
      rmcookie('bowling');
      return window.location.reload();
    }
    return res;
  }
  // const request = (a, ...b) => fetch(API_URL + a, ...b);

  async function deleteGame(game){
    if(window.confirm(`Score: ${game.total}\nStrikes: ${game.stat.strike}\nDate: ${new Date(game.game.time)}\n\nDelete this game?`)){
      const res = await request('/delete-game', { method: 'POST', body: JSON.stringify({ gid: game.game.id }) });
      if(!res.ok) window.confirm('deletion failed');
      return res.ok;
    }else return false;
  }

  return (
    <CookiesProvider>
      <APIContext.Provider value={{
        user, request, deleteGame
      }}>
        {children}
      </APIContext.Provider>
    </CookiesProvider>
  );
}