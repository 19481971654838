import React, { useState, useMemo, useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { redirect, useSearchParams } from "react-router-dom";

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import ScoreBoard from '../components/ScoreBoard';
import PinView from '../components/PinView';
import { useAPIContext } from '../contexts/API';
import { ROUNDS, FULL, PINS, Game, last, mkArray } from '../utils';

const ScoreBoardContainer = styled.div`
  overflow-x: scroll;
  max-width: 100%;
  // margin: 20px 0px;
`;
const Frames = styled.div`
  display: flex;
  width: 100%;
  max-width: 500px;
  justify-content: space-between;
  align-items: flex-start;
  & > div{
    box-sizing: border-box;
    user-select: none;
    text-align: center;
  }
  & > div:first-of-type, & > div:last-child {
    padding: 5px 12px;
    border: 1px black solid;
    background: #cdcdcd;
    width: 25%;
    max-width: 125px;
    cursor: pointer;
    &:active {
      opacity: 0.5;
    }
  }
  & > div:first-of-type {
    border-left: none;
  }
  & > div:last-child {
    border-right: none;
  }
`;
const Focused = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  max-width: 250px;
`;
const FocusedTop = styled.div`
  padding: 5px 12px;
`;
const Scores = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  & > div {
    width: -webkit-fill-available;
    border: 2px solid #8c8c8c;
    padding: 12px 0;
    box-sizing: border-box;
  }
  & > div {
    border-right: none;
  }
  & > div.focus {
    border-right: 2px solid red;
    border-color: red;
  }
  & > div.focus ~ div {
    border-right: 2px solid #8c8c8c;
    border-left: none;
  }
`;
const Pin = styled.div`
  width: 13%;
  height: 13%;
  border-radius: 50%;
  background: #c8c8c8;
  cursor: pointer;
  user-select: none;
  &:active {
    opacity: 0.5;
  }
  &.on {
    background: red;
    outline: 2px solid black;
    outline-offset: 4px;
  }
  &.hidden {
    display: none;
  }
  &.close {
    pointer-events: none;
  }
`;
const Btns = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  padding-bottom: 30px;
`;

const warning = e => { e.preventDefault(); e.returnValue = ''; }

export default function Recorder({}){
  const { request } = useAPIContext();
  const [searchParams] = useSearchParams();
  const category = searchParams.get("category") || "practice";
  const cat = category.split(".")[0];
  // const [focus, setFocus] = useState(0);
  const [records, setRecords] = useState([0]);
  const finished = useMemo(() => (new Game(records.slice(0, -1), cat)).complete, [records]);
  const scoreboard = useMemo(() => new Game(finished ? records.slice(0, -1) : records, cat), [records]);
  const focus = finished ? records.length - 2 : records.length - 1;
  useEffect(() => {
    // console.log(scoreboard.rounds.map(e => e.join(' ')).join('\n'));
    // console.log(scoreboard);
    // console.log(scoreboard.throws);
    // console.log(scoreboard.scores);
  }, [scoreboard]);
  const hidden = scoreboard.at_round[focus] === scoreboard.at_round[focus-1] ? records[focus-1] : 0;
  const pins = finished ? FULL : FULL - records[focus];
  const setPins = x => setRecords(r => r.map((e, i) => i == focus ? x : e));
  const at_round = Math.min(scoreboard.at_round[focus], ROUNDS);
  const SBRef = useRef(null);

  useEffect(() => {
    try{
      const d = JSON.parse(window.localStorage.getItem('record'));
      const g1 = new Game(d.slice(0, -1), cat);
      const g2 = new Game(d, cat);
      if(!(g1.valid && (g1.complete || g2.valid)))
        throw "jizz";
      setRecords(d);
    }catch(e){
      console.error(e);
      window.localStorage.removeItem('record');
    }
  }, []);

  useEffect(() => {
    if(records.length == 1 && records[0] == 0) return;
    window.localStorage.setItem('record', JSON.stringify(records));
  }, [records]);

  const [fetching, setFetching] = useState(false);
  async function submit(){
    if(fetching) return;
    if(!finished)
      if(!window.confirm("The game is not yet finished. Are you sure to submit?"))
        return;
    setFetching(true);
    try{
      console.log('submit');
      let res = await request('/new-game', {
        method: 'POST',
        body: JSON.stringify({ game: records.slice(0, -1), category })
      });
      if(res.ok){
        console.log("return redirect('/');");
        window.localStorage.removeItem('record');
        window.removeEventListener("beforeunload", warning);
        window.location = '/';
        return;
      }else{
        window.confirm("submission failed");
      }
    }finally{
      setFetching(false);
    }
  }

  // useEffect(() => {
  //   if(records[0] === 0 && records.length === 1) return;
  //   window.addEventListener("beforeunload", warning);
  //   return () => window.removeEventListener("beforeunload", warning);
  // }, [records[0], records.length]);

  return (
    <>
      <LinearLayout style={{ minHeight: '100vh', justifyContent: 'space-between' }}>
        <Header />
        <h3>{cat}</h3>
        <ScoreBoardContainer ref={SBRef}>
          <ScoreBoard
            style={{ width: '600px', boxSizing: 'border-box' }}
            game={scoreboard}
            category={cat}
            parent={SBRef.current}
            focus={focus} />
        </ScoreBoardContainer>
        <Frames>
          <div style={at_round === 1 ? { visibility: 'hidden' } : {}}>{at_round-1}</div>
          <Focused>
            <FocusedTop>{at_round}</FocusedTop>
            <Scores>
              {last(scoreboard.rounds).map((e, i, a) =>
                <div className={i === a.length-1 ? "focus" : ""} key={i}>{e}</div>)}
              {mkArray(Math.max(0, 2 - last(scoreboard.rounds).length), (e, i) => <div key={i}></div>)}
            </Scores>
          </Focused>
          <div style={at_round == ROUNDS ? { visibility: 'hidden' } : {}}>{at_round+1}</div>
        </Frames>
        <PinView>
          {(new Array(PINS)).fill(0).map((e, i) =>
            <Pin
              key={i+1}
              className={finished ? "close" : (hidden & (1 << i)) ? "hidden" : (pins & (1 << i)) ? "on" : undefined}
              onClick={() => setPins(pins ^ FULL ^ (1 << i))} />)}
        </PinView>
        <Btns>
          <Button onClick={() => {
            setRecords(e => e.slice(0, -1));
          }} disabled={focus === 0}>Undo</Button>
          <Button onClick={() => setPins(0)} disabled={finished}>Full</Button>
          <Button onClick={() => setPins(FULL ^ hidden)} disabled={finished}>Clear</Button>
          {/*<Button>Foul</Button>*/}
          <Button onClick={() => {
            setRecords(e => e.concat([0]));
          }} disabled={finished}>OK</Button>
          <Button onClick={submit}>Submit</Button>
        </Btns>
      </LinearLayout>
    </>
  );
}