import React, { useEffect, useRef } from 'react';
import styled from "@emotion/styled";

import { last, sum, FULL, ROUNDS, Game, mkArray } from '../utils';

const config = {
  height: [
    60,
    90,
    90,
  ],
  width: [100, 250]
};

function line(ctx, x1, y1, x2, y2, lw){
  ctx.beginPath(); ctx.lineWidth = lw; ctx.moveTo(x1, y1); ctx.lineTo(x2, y2); ctx.stroke();
}
function text(ctx, str, x, y, {
  color='black', fs=40, ff="monospace", vari=""
}={}){
  ctx.font = `${vari} ${fs}px ${ff}`;
  ctx.fillStyle = color;
  ctx.textBaseline = "middle";
  ctx.textAlign = "center";
  ctx.fillText(str, x, y);
}

// function score_calc(x){
//   return x.map(e => 10).concat([100]);
// }

function mark(a, j){
  if(j > 0){
    const last = mark(a, j-1);
    if(last != '/' && last != 'X' && a[j-1] + a[j] === 10)
      return "/";
  }
  if(a[j] === 10) return "X";
  return a[j];
}

export default function ScoreBoard({ game, focus=null, parent=null, category="practice", ...props }){
  const scores = game.scores.map(e => e);
  const max_score = new Game([...game.throws, game.remaining, ...mkArray(ROUNDS + 2, e => FULL)], category).total;
  if(scores.length < game.rounds.length){
    scores.push(new Game([
      ...game.throws, ...mkArray(ROUNDS + 2, e => 0)
    ], category).scores[game.rounds.length-1]);
  }
  const sum_score = sum(scores);
  const canvasRef = useRef(null);

  useEffect(() => {
    if(!parent || !canvasRef.current) return;
    parent.scrollTo(parent.scrollWidth * (
      (2 * config.width[0] * (Math.min(game.at_round[focus], ROUNDS) - 5))
      / (2 * config.width[0] * ROUNDS + config.width[1])
    ), 0);
  }, [focus]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if(!canvas) return console.error('jizz');
    const ctx = canvas.getContext('2d');
    ctx.fillStyle = 'white'; ctx.fillRect(0, 0, canvas.width, canvas.height);
    const lw = 8;

    let w, h;
    if(focus !== null){
      ctx.fillStyle = '#f4cdcd'; ctx.fillRect(
        2 * config.width[0] * (Math.min(game.at_round[focus], ROUNDS) - 1), 0,
        2 * config.width[0], sum(config.height),
      );
    }

    line(ctx, lw/2, 0, lw/2, canvas.height, lw);
    for(let i = 0; i < ROUNDS; i++){
      w = (2 * i + 2) * config.width[0];
      line(ctx, w, 0, w, canvas.height, lw);
      w = (2 * i + 1) * config.width[0];
      if(i < ROUNDS-1)
        line(ctx, w, sum(config.height.slice(0, 1)), w, sum(config.height.slice(0, 2)), 0.6 * lw);
    }
    w = 2 * (ROUNDS - 1/3) * config.width[0];
    line(ctx, w, sum(config.height.slice(0, 1)), w, sum(config.height.slice(0, 2)), 0.6 * lw);
    w = 2 * (ROUNDS - 2/3) * config.width[0];
    line(ctx, w, sum(config.height.slice(0, 1)), w, sum(config.height.slice(0, 2)), 0.6 * lw);
    line(ctx, canvas.width - lw/2, 0, canvas.width - lw/2, canvas.height, lw);

    /////////////////////////////////////
    h = sum(config.height.slice(0, 0)); h += lw / 2;
    line(ctx, 0, h, canvas.width, h, lw);

    for(let i = 0; i < ROUNDS; i++){
      w = (2 * i + 1) * config.width[0];
      text(ctx, `${i+1}`, w, config.height[0] / 2 + lw / 2);
    }
    w = (2 * ROUNDS) * config.width[0] + config.width[1] / 2;
    text(ctx, (game.complete ? `total` : `max`), w, config.height[0] / 2 + lw / 2);

    /////////////////////////////////////
    h = sum(config.height.slice(0, 1));
    line(ctx, 0, h, canvas.width, h, lw);

    h = (sum(config.height.slice(0, 1)) + sum(config.height.slice(0, 2))) / 2;
    for(let i = 0; i < game.rounds.length; i++){
      for(let j = 0; j < game.rounds[i].length; j++){
        const d = (i < ROUNDS-1 ? 1 : 2/3);
        w = (2 * i + d * (j + 0.5)) * config.width[0];
        text(ctx, `${mark(game.rounds[i], j)}`, w, h, {
          fs: 60, vari: "bold",
          ...(focus !== null && game.index[i][j] === focus ? { color: 'red' } : {})
        });
      }
    }

    /////////////////////////////////////
    h = sum(config.height.slice(0, 2));
    for(let i = 0; i < ROUNDS-1; i++){
      line(ctx, (2 * i + 1) * config.width[0], h, (2 * i + 2) * config.width[0], h, 0.6 * lw);
    }
    line(ctx, (2 * ROUNDS - 2) * config.width[0], h, (2 * ROUNDS) * config.width[0], h, 0.6 * lw);

    h = (sum(config.height.slice(0, 2)) + sum(config.height.slice(0, 3))) / 2;
    for(let i = 0; i < scores.length; i++){
      w = (2 * i + 1) * config.width[0];
      text(ctx, `${sum(scores.slice(0, i+1))}`, w, h, { fs: 60, vari: "bold" });
    }

    h = sum(config.height.slice(0, 2));
    w = (2 * ROUNDS) * config.width[0] + config.width[1] / 2;
    text(ctx, (game.complete ? `${sum_score}` : `${max_score}`), w, h, { fs: 80, vari: "bold" });

    /////////////////////////////////////
    h = sum(config.height.slice(0, 3)); h -= lw / 2;
    ctx.beginPath(); ctx.lineWidth = lw; ctx.moveTo(0, h); ctx.lineTo(canvas.width, h); ctx.stroke();
    return () => ctx.clearRect(0, 0, canvas.width, canvas.height);
  }, [scores, game.rounds, focus]);

  return (
    <canvas
      ref={canvasRef}
      width={2 * config.width[0] * ROUNDS + config.width[1]}
      height={sum(config.height)}
      {...props} />
  );
}