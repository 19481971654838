import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ScatterChart, Scatter, CartesianGrid, XAxis, YAxis, ResponsiveContainer } from 'recharts';

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import ScoreBoard from '../components/ScoreBoard';
import ToggleButton from '../components/ToggleButton';
import Button from '../components/Button';
import PinView from '../components/PinView';
import { PINS, Game, sum, dateString, reversed, Stat, percentage } from '../utils';
import { API_URL, useAPIContext } from '../contexts/API';

const LL = styled(LinearLayout)`
  min-height: 100vh;
  justify-content: space-between;
  & > * {
    width: 100%;
    margin-bottom: 30px;
    max-width: 600px;
  }
  & > *:first-of-type {
    max-width: initial;
  }
`;

const Container = styled(LinearLayout)`
  background: #cdcdcd;
  margin: 10px;
  padding: 5px 8px;
  width: -webkit-fill-available;
  border-radius: 10px;
  max-height: 300px;
  overflow-y: auto;
  flex-wrap: nowrap;
  justify-content: flex-start;
  &::-webkit-scrollbar {
    display: none;
  }
`;
const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  & > * {
    margin: 0;
  }
`;
const Item = styled.div`
  margin-top: 5px;
  width: 100%;
  font-family: consolas;
  background: #efefef;
  white-space: pre-wrap;
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  box-sizing: border-box;
  font-size: small;
  cursor: pointer;
`;
const Key = styled.span`
  color: #808080;
`;
const Numbers = styled.div`
  display: flex;
  // justify-content: space-around;
  width: 100%;
  & > h3, h2, h1 {
    margin: 0;
    padding-bottom: 8px;
    // width: -webkit-fill-available;
    // max-width: calc(100% / 3);
    width: calc(100% / 3);
    text-align: center;
  }
  & > * + * {
    border-left: 1px solid gray;
  }
`;
const Pin = styled.div`
  width: 13%;
  height: 13%;
  border-radius: 50%;
  border: 1px solid #c8c8c8;
  user-select: none;
`;
const Btns = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
`;
function bgstyle(x){ return { background: `linear-gradient(to top, red ${100-x*100}%, #c8c8c8 ${100-x*100}%)` }; }

function Sub({ children }){ return <sub><sub>{children}</sub></sub>; }

function Shape({ cx, cy }){
  return(
    <circle
      // {...props}
      cx={cx} cy={cy}
      stroke="white"
      strokeWidth="1.5"
      fill="#f0523c"
      r="4" />
  );
}

export default function Statistics({}){
  const { request } = useAPIContext();
  const [dateFilter, setDateFilter] = useState("Today");
  const now = new Date();
  const [data, setData] = useState(false);
  const { games } = data || {};
  const scores = useMemo(() => games &&
    games.map(e => Object.assign(new Game(e.data), { game: e }))
        .filter(e => e.complete)
        .filter(e => {
          if(dateFilter === "Today")
            return now.toDateString() === new Date(e.game.time).toDateString();
          if(dateFilter === "This week"){
            const then = new Date(e.game.time);
            if(now.getTime() - then.getTime() <= 6 * 24 * 60 * 60 * 1000 && now.getDay() >= then.getDay())
              return true;
            if(now.getDay() === 6 && then.getDay() !== 6 && now.getTime() - then.getTime() <= 7 * 24 * 60 * 60 * 1000)
              return true;
            return false;
          }
          return true;
        })
        .map(e => ({ ...e, stat: new Stat(e) })), [games, dateFilter]);
  const frames = games && sum(scores.map(e => e.stat.n));

  useEffect(() => {
    (async () => {
      let res = await request('/list-game?category=practice');
      if(res.ok){
        setData({ games: (await res.json()).data });
      }else{
        window.confirm("error");
      }
    })();
  }, []);

  if(data === false) return '';
  return (
    <>
      <LL>
        <Header />
        <Btns>
          {["All", "This week", "Today"].map(e =>
            <Button
              key={e}
              onClick={() => setDateFilter(e)}
              style={{ opacity: dateFilter === e ? 1 : 0.5 }}
            >{e}</Button>)}
        </Btns>
        <LinearLayout>
          <h2 style={{ margin: 0, width: '90%' }}>First Throw</h2>
          <PinView>
            {(new Array(PINS)).fill(0).map((e, i) => sum(scores.map(s => sum(
              s.states.map(r => Number(Boolean(r[0] & (1 << i))))
            ))) / frames).map((e, i) =>
              <React.Fragment key={`${dateFilter}-${i+1}`}>
                <Pin style={bgstyle(e)} />
                <h5 style={{ margin: 0 }}>{percentage(e)}</h5>
              </React.Fragment>)}
          </PinView>
        </LinearLayout>
        <div>
          <Numbers>
            <h3></h3>
            <h3>Avg. Score</h3>
            <h3></h3>
          </Numbers>
          <Numbers>
            <h1></h1>
            <h1>{(sum(scores.map(e => e.total )) / scores.length).toFixed(2)}</h1>
            <h1></h1>
          </Numbers>
        </div>
        <div>
          <Numbers>
            <h3>Strike</h3>
            <h3>Spare</h3>
            <h3>Score<Sub>/ Frame</Sub></h3>
          </Numbers>
          <Numbers>
            <h1>{percentage(sum(scores.map(e => e.stat.strike)) / frames)}</h1>
            <h1>{percentage(sum(scores.map(e => e.stat.spare )) / frames)}</h1>
            <h1>{(sum(scores.map(e => e.total )) / frames).toFixed(2)}</h1>
          </Numbers>
        </div>
        {/*<div style={{ width: '100%' }}>
          <Numbers>
            <h3>Other Stats</h3>
            <h3>Other Stats</h3>
            <h3>Other Stats</h3>
          </Numbers>
          <Numbers>
            <h1>N</h1>
            <h1>N</h1>
            <h1>N</h1>
          </Numbers>
        </div>*/}
        {/*scores.map((e, i) =>
            <ScoreBoard
              key={i}
              style={{ width: '100%' }}
              rounds={e.rounds}
              scores={e.scores} />)*/}
      </LL>
    </>
  );
}