import React, { useEffect } from 'react';

import { useAPIContext } from '../contexts/API';

export default function SignOut(){
  const { user, request } = useAPIContext();
  // TODO: two options -- signout or change password
  useEffect(() => {
    (async () => {
      let res = await request('/logout', {
        method: 'POST'
      });
      if(res.ok){
        window.location = '/';
      }else{
        window.confirm("sign out failed");
      }
    })();
  }, []);
  return "";
}