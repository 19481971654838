import React from "react";
import styled from "@emotion/styled";

const Btn = styled.div`
  cursor: pointer;
  background: #cdcdcd;
  padding: 8px 12px;
  border-radius: 8px;
  user-select: none;
  &:active, &.disabled {
    opacity: 0.5;
  }
`;

export default function Button({ disabled, ...props }){
  return (
    <Btn
      {...props}
      className={disabled ? "disabled" : ""}
      {...(disabled ? { onClick: ()=>{} } : {})}
    />
  );
}