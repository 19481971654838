import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';

const LL = styled(LinearLayout)`
  min-height: 100vh;
  justify-content: flex-start;
  & > input{
    margin-bottom: 15px;
  }
  & > label{
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

const Btns = styled.div`
  display: flex;
  & > * {
    margin: 10px;
  }
`;

export default function Recorder({}){
  const { request } = useAPIContext();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(true);

  useEffect(() => {
    try{
      const cred = window.localStorage.getItem('credential');
      console.log(cred);
      const [un] = cred.split("$", 1);
      setUsername(un); console.log(un);
      setPassword(cred.slice(1+un.length)); console.log(cred.slice(1+un.length));
    }catch(e){ console.log(e); }
  }, [setUsername, setPassword]);

  async function login(){
    let res = await request('/login', {
      method: 'POST',
      body: JSON.stringify({
        username, password
      })
    });
    if(res.ok){
      console.log(res);
      if(remember){
        window.localStorage.setItem('credential', `${username}$${password}`);
      }else{
        window.localStorage.removeItem('credential');
      }
      window.location.reload();
    }else{
      window.confirm("login failed");
    }
  }

  async function signup(){
    if(!username) return window.confirm("Username cannot be empty.");
    if(password.length < 8)
      return window.confirm("Password must have at least 8 characters.");
    if(!window.confirm(`Signing up with\nUsername: ${username}\nPassword: ${password}`))
      return;
    let res = await request('/signup', {
      method: 'POST',
      body: JSON.stringify({
        username, password
      })
    });
    if(res.ok){
      console.log(res);
      window.location = '/';
    }else{
      window.confirm("Failed to register");
    }
  }

  async function login2(){
    setUsername(window.prompt("Username:"));
    setPassword(window.prompt("Password:"));
  }

  return (
    <>
      <LL>
        <Header />

        <label>Username</label>
        <input value={username} onChange={e => setUsername(e.target.value)} type="text" />
        <label>Password</label>
        <input value={password} onChange={e => setPassword(e.target.value)} type="password" />
        <span>
          <input type="checkbox" style={{ transform: "translateY(2px)" }} checked={remember} onChange={e => setRemember(e.target.checked)} />
          <label> remember my account</label>
        </span>
        <Btns>
          <Button onClick={login}>Login</Button>
          {/*<Button onClick={login2}>Can't type?</Button>*/}
          <Link className="link" to="/signup"><Button>Sign Up</Button></Link>
        </Btns>
      </LL>
    </>
  );
}