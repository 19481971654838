import React, { useState, useMemo, useEffect } from "react";
import styled from "@emotion/styled";

import LinearLayout from '../components/LinearLayout';
import Header from '../components/Header';
import Button from '../components/Button';
import { useAPIContext } from '../contexts/API';

const LL = styled(LinearLayout)`
  min-height: 100vh;
  justify-content: flex-start;
  & > input{
    // margin-bottom: 15px;
  }
  & > label{
    margin-top: 15px;
    margin-bottom: 5px;
  }
`;

const Btns = styled.div`
  display: flex;
  & > * {
    margin: 10px;
  }
  margin-top: 10px;
`;

function Admin({}){
  const { request, user } = useAPIContext();
  const [username, setUsername] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  async function change(){
    if(newPassword.length < 8)
      return window.confirm("Password must have at least 8 characters.");
    if(newPassword != newPassword2)
      return window.confirm("Password confirmation failed.");
    if(!window.confirm(`Change password?`))
      return;
    let res = await request('/change-password', {
      method: 'POST',
      body: JSON.stringify({
        username, new_password: newPassword
      })
    });
    if(res.ok){
      console.log(res);
      // window.location = '/';
      setUsername("");
      setNewPassword("");
      setNewPassword2("");
    }else{
      window.confirm(`[Error]\n${await res.text()}`);
    }
  }

  if(user?.sub?.role !== "admin") return "";
  return (
    <>
      <LL>
        <h3>Reset User Password (Admin)</h3>
        <label>Username</label>
        <input value={username} onChange={e => setUsername(e.target.value)} type="text" />
        <label>New Password</label>
        <input value={newPassword} onChange={e => setNewPassword(e.target.value)} type="password" />
        <label>Password confirmation</label>
        <input value={newPassword2} onChange={e => setNewPassword2(e.target.value)} type="password" style={
          newPassword2 && newPassword2 != newPassword ? { borderColor: 'red' } : {}
        }/>
        <Btns>
          {/*<Button onClick={login2}>Can't type?</Button>*/}
          <Button onClick={change}>Change</Button>
        </Btns>
      </LL>
    </>
  );
}

export default function ChangePwd({}){
  const { request } = useAPIContext();
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");

  async function change(){
    if(newPassword.length < 8)
      return window.confirm("Password must have at least 8 characters.");
    if(newPassword != newPassword2)
      return window.confirm("Password confirmation failed.");
    if(!window.confirm(`Change password?`))
      return;
    let res = await request('/change-password', {
      method: 'POST',
      body: JSON.stringify({
        password, new_password: newPassword
      })
    });
    if(res.ok){
      console.log(res);
      window.location = '/';
    }else{
      window.confirm("Passweord incorrect");
    }
  }

  return (
    <>
      <LL>
        <Header />

        <label>Original Password</label>
        <input value={password} onChange={e => setPassword(e.target.value)} type="password" />
        <label>New Password</label>
        <input value={newPassword} onChange={e => setNewPassword(e.target.value)} type="password" />
        <label>Password confirmation</label>
        <input value={newPassword2} onChange={e => setNewPassword2(e.target.value)} type="password" style={
          newPassword2 && newPassword2 != newPassword ? { borderColor: 'red' } : {}
        }/>
        <Btns>
          {/*<Button onClick={login2}>Can't type?</Button>*/}
          <Button onClick={change}>Change</Button>
        </Btns>

        {<Admin />}
      </LL>
    </>
  );
}